.music-description{
    margin-bottom: 15px;
}

.music-title{
    font-size: 40px;
    margin-bottom:10px;
    color: black
}

.music-intro{
    margin-bottom: 30px;
}

.music-player{
    padding-bottom: 20px;
}