@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');


body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
    --dollar:#FFD12D;
  
    --background: var(--dollar);
    --accent: var(--white);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }
  
  * {
    font-family: lalezar, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
  
  h1,
  h2,
  h3 {
    font-family: lalezar, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  h3{
    font-size: 32px;
  }
  
  h4{
    font-size: 24px;
    line-height: 25px;
  }




  h2 {
    font-size: 30px;
    font-weight: 400;

  }
  
  p {
    font-size: 16px;
  }
  
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(
      rgba(0, 0, 0, 0) 1px,
      var(--background) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
  }
  
  footer::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent);
    opacity: 0.2;
  }
  
  footer svg {
    margin-right: 20px;
  }
  
  footer a {
    text-decoration: none;
    color: var(--accent);
  }
  
  code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: var(--background);
  }
  
  html {

  }

  .project-page{
    scroll-snap-type: y mandatory;

  }
  
  h1 {
    font-size: 56px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0;
  }
  
  .image-number {
    margin: 0;
    color: var(--accent);
    left: calc(50% + 250px);
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -3px;

    line-height: 1.2;
    position: absolute;
  }
  
  section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
  }
  
  section > div {
    width: 450px;
    height: 450px;
    object-fit: cover;
    position: relative;
    scroll-snap-align: center;
    max-height: 90vh;
    background: var(--white);
    overflow: hidden;
  }

  .project-name{
    text-align: center;
    margin-top: 30%;
    margin-bottom:0;
    color: white;

  }

  .project-role{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
    color: white;

  }

  .project-description{
    text-align: center;
    width:80%;
    margin: auto;
    color: white;
  }


  
  .dollars-projects {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
  



  @media (max-width: 960px) {
    .image-number  {
      margin: 0;
      color: var(--accent);
      left: calc(50% + 150px);
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -3px;
      line-height: 1.2;
      position: absolute;
    }
    section > div {
      scroll-snap-align: center;
      width: 350px;
      height: 350px;
    }
}

@media (max-width:768px){
  h1 {
    font-size: 36px;
    
  }

  h3{
    font-size: 24px;
  }
  
  h4{
    font-size: 20px;
  }




  h2 {
    font-size: 30px;
  }
  
  p{
    font-size:12px;
  }

  section > div {
    scroll-snap-align: center;
    width: 250px;
    height: 250px;
  }


  .image-number {
    margin: 0;
    color: var(--accent);
    left: calc(80%);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -3px;

    line-height: 1.2;
    position: absolute;
  }
  
}


  .Navbar-projects {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    top: 0px;
    color: var(--black);
  }